<template>
  <div class="container" style="height: 600px">
    <form>
      <div class="form-group mt-5">
        <label for="exampleFormControlFile1">ทะเบียนสรรพสามิต&nbsp;</label>
        <input
          type="file"
          @change="onChangeA1"
          class="form-control-file"
          id="exampleFormControlFile1"
        />
      </div>
      <!-- <div class="form-group mt-5">
        <label for="exampleFormControlFile1">B1&nbsp;</label>
        <input
          type="file"
          @change="onChangeB1"
          class="form-control-file"
          id="exampleFormControlFile1"
        />
      </div> -->
      <!-- <div class="form-group mt-5">
        <label for="exampleFormControlFile1">B2&nbsp;</label>
        <input
          type="file"
          @change="onChangeB2"
          class="form-control-file"
          id="exampleFormControlFile1"
        />
      </div> -->
    </form>
    <!-- <button @click="merge()" type="button" class="mt-5 btn btn-secondary">
      merge
    </button> -->
    <div class="form-group mt-5">
      <label for="exampleFormControlFile1">การตรวจเยี่ยมโรงอุตสาหกรรม&nbsp;</label>
      <input
        type="file"
        @change="onChangeC1"
        class="form-control-file"
        id="exampleFormControlFile1"
      />
    </div>
    <div class="form-group mt-5">
      <label for="exampleFormControlFile1">การชำระภาษี&nbsp;</label>
      <input
        type="file"
        @change="onChangeNew"
        class="form-control-file"
        id="exampleFormControlFile1"
      />
    </div>
    
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import DetailExciseService from "../services/DetailExciseService";
import LiquorFactories from "../services/LiquorFactories";
import ReportLiquorTax from "../services/ReportLiquorTax";
import ReportService from "../services/ReportService";
import MergeLiquorReportService from "../services/MergeLiquorReportService";
// import moment from 'moment'

export default {
  name: "App",
  components: {},
  data() {
    return {
      type: 0,
    };
  },
  methods: {
    onChangeA1(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        var count = 0;
        readXlsxFile(event.target.files[0]).then((data) => {
          for (let i = 1; i < data.length; i++) {
            if (data[i][2] != null) {
              count = count + 1;
              if (data[i][17] == "ยืนยัน") {
                data[i][17] = true;
              } else {
                data[i][17] = false;
              }
              var registration_date = ''
              if (data[i][12]) {
                registration_date = data[i][12].split('/')
                registration_date = (registration_date[2])+'-'+registration_date[1]+'-'+registration_date[0]
              }
              var status_date = ''
              if (data[i][13]) {
                status_date = data[i][13].split('/')
                status_date = (status_date[2])+'-'+status_date[1]+'-'+status_date[0]
              }
              var detail = {
                excise_id: data[i][1],
                excise_no: data[i][2],
                excise_name: data[i][3],
                excise_by: data[i][4],
                industrial_name: data[i][5],
                industrial_no: data[i][6],
                industrial_moo: data[i][7],
                industrial_subdistrict: data[i][8],
                industrial_district: data[i][9],
                industrial_province: data[i][10],
                industrial_zipcode: data[i][11],
                registration_date: registration_date,
                status_date: status_date,
                registration_status: data[i][14],
                latitude: data[i][15],
                longitude: data[i][16],
                coordinate_status: data[i][17],
                image: data[i][18],
              };
                console.log(detail);
              DetailExciseService.createDetailExcise(detail)
                .then(() => {
                    console.log(data.length+1);
                    console.log(i);
                    if (data.length == i+1) {
                     alert('นำเข้าข้อมูลเรียบร้อย') 
                    }
                })
                .catch((err) => {
                  console.log(err);
                  // console.log(detail);
                  // console.log(data[i][1]);
                  // console.log(e);
                });
            }
          }
        });
      }
    },
    onChangeNew(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        readXlsxFile(event.target.files[0]).then((data) => {
          // console.log(data.length);
          for (let i = 1; i < data.length; i++) {    
            console.log(data[i]);
            var factory_code = data[i][1].substring(0,17)
            var factory_name = data[i][1].substring(6,data[i][1].length)
            // console.log(factory_code);
            // console.log(factory_name);
            // console.log(data[i][2]);
            var tax_payment_date = ''
            if (data[i][2]) {
              tax_payment_date = data[i][2].split('/')
              tax_payment_date = (tax_payment_date[2])+'-'+tax_payment_date[1]+'-'+tax_payment_date[0]
              }
             var detail = {
            no: data[i][0],
            tax_payment_date: tax_payment_date,
            brand_name: data[i][4],
            factory_code: factory_code,
            number: data[i][7],
            degree: data[i][5],
            size: data[i][6],
            per_each: data[i][9],
            volume_liquor: data[i][8],
            industrial_plant_name: factory_name,
            type: 0,
            liquor_tax: data[i][10],
            net_tax: data[i][11],
          };
          console.log(detail);
          MergeLiquorReportService.createMergeLiquorReport(detail)
            .then(() => {
              if (data.length == i+1) {
                     alert('นำเข้าข้อมูลเรียบร้อย') 
                    }
            })
            .catch((err) => {
              console.log(detail);
              console.log(err);
            });
          }
        });
      }
    },
    getTypeMerge() {
      MergeLiquorReportService.getType()
        .then((res) => {
          // console.log(res.data);
          if (res.data == "") {
            this.type = 0;
            // console.log(this.type);
          } else {
            this.type = res.data.type;
          }
        })
        .catch((err) => {
          console.log(err);
          this.type = 0;
        });
    },
    getTypeReportLiquorTax() {
      ReportLiquorTax.getType()
        .then((res) => {
          console.log(res.data);
          if (res.data == "") {
            this.type = 1;
            console.log(this.type);
          } else {
            this.type = res.data.type + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.type = 1;
        });
    },
    getTypeLiquorFactories() {
      LiquorFactories.getType()
        .then((res) => {
          console.log(res.data);
          if (res.data == "") {
            this.type = 1;
            console.log(this.type);
          } else {
            this.type = res.data.type + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.type = 1;
        });
    },
    onChangeB1(event) {
      this.getTypeLiquorFactories();
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        readXlsxFile(event.target.files[0]).then((data) => {
          for (let i = 6; i < data.length; i++) {
            if (data[i][0] != "รวม") {
              var detail = {
                no: data[i][0],
                register_no: data[i][1],
                brand_name: data[i][2],
                factory_code: data[i][3],
                liquor_factory_name: data[i][4],
                factory_address: data[i][5],
                number: data[i][6],
                degree: data[i][7],
                size: data[i][8],
                per_each: data[i][9],
                volume_liquor: data[i][10],
                amount_item: data[i][11],
                amount: data[i][12],
                book_stamp: data[i][13],
                type: this.type,
              };
              // console.log(detail);

              LiquorFactories.createLiquorFactory(detail)
                .then(() => {
                  //   console.log(res);
                })
                .catch((err) => {
                  console.log(data[i][0]);
                  console.log(detail);
                  console.log(err);
                });
            }
          }
        });
      }
    },
    onChangeB2(event) {
      this.getTypeReportLiquorTax();
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        readXlsxFile(event.target.files[0]).then((data) => {
          for (let i = 8; i < data.length; i++) {
            var detail = {};
            if (data[i][0] != "รวมทั้งสิ้น") {
              if (data[i][1] == null) {
                detail = {
                  no: data[i - 1][0],
                  industrial_plant_name: data[i - 1][1],
                  tax_payment_date: data[i - 1][2],
                  excise_number: data[i - 1][3],
                  brand_name: data[i - 1][4],
                  degree: data[i][5],
                  packing_size: data[i][6],
                  number_bottles: data[i][7],
                  volume_liquor: data[i][8],
                  liquor_tax: data[i][9],
                  tax_refund: data[i][10],
                  net_tax: data[i][11],
                  type: this.type,
                };
                // console.log(data[i],detail);
              } else {
                detail = {
                  no: data[i][0],
                  industrial_plant_name: data[i][1],
                  tax_payment_date: data[i][2],
                  excise_number: data[i][3],
                  brand_name: data[i][4],
                  degree: data[i][5],
                  packing_size: data[i][6],
                  number_bottles: data[i][7],
                  volume_liquor: data[i][8],
                  liquor_tax: data[i][9],
                  tax_refund: data[i][10],
                  net_tax: data[i][11],
                  type: this.type,
                };
              }
              ReportLiquorTax.createReportLiquorTax(detail)
                .then(() => {
                  //   console.log(res);
                })
                .catch((err) => {
                  console.log(data[i][0]);
                  console.log(detail);
                  console.log(err);
                });
            }
          }
        });
      }
    },
    merge() {
      ReportLiquorTax.getReportMergeLiquorTaxs().then((res) => {
        console.log(res.data);
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].type > 0) {
            var detail = {
            no: res.data[i].no,
            tax_payment_date: this.convertdatemerge(
              res.data[i].tax_payment_date
            ),
            brand_name: res.data[i].brand_name,
            factory_code: res.data[i].factory_code,
            number: res.data[i].number,
            degree: res.data[i].degree,
            size: res.data[i].size,
            per_each: res.data[i].per_each,
            volume_liquor: res.data[i].volume_liquor,
            industrial_plant_name: res.data[i].industrial_plant_name,
            type: res.data[i].type,
          };
          MergeLiquorReportService.createMergeLiquorReport(detail)
            .then(() => {
              // console.log(res.data);
            })
            .catch((err) => {
              console.log(res.data[i].id);
              console.log(detail);
              console.log(err);
            });
          }          
        }
      });
    },
    onChangeC1(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        readXlsxFile(event.target.files[0]).then((data) => {
          for (let i = 1; i < data.length; i++) {
            var detail = {};
            var report_date= ''
            if (data[i][3]) {
              report_date = data[i][3].split('/')
              report_date = (report_date[2])+'-'+report_date[1]+'-'+report_date[0]
              }
            detail = {
              no: data[i][0],
              excise_no: data[i][1],
              plan_no: data[i][2],
              report_date: report_date,
              verify: data[i][4],
              period: data[i][5],
              excise_tax: data[i][6],
              fine: data[i][7],
              vat: data[i][8],
              Increased_taxes: data[i][9],
              sum: data[i][10],
              remark: data[i][11],
            };
            // console.log(detail);
            ReportService.createReport(detail)
              .then(() => {
                //   console.log(res);
                if (data.length == i+1) {
                     alert('นำเข้าข้อมูลเรียบร้อย') 
                    }
              })
              .catch((err) => {
                console.log(detail);
                console.log(err);
              });
          }
        });
      }
    },
    convertdatemerge(datetimes) {
      // console.log(datetimes);
      if (datetimes) {
        const date = new Date(datetimes);
        date.setDate(date.getDate() + 1);
        // console.log(date);
        return date;
      }
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        // console.log(date);
        const result = date.toLocaleDateString("en-EN");
        return result;
      }
    },
    convertdate(date) {
      var streetaddress = String(date);
      var d = streetaddress.substring(0, streetaddress.indexOf("/"));
      var m = streetaddress.substring(5, streetaddress.indexOf("/"));
      m = m.substring(1, 3);
      var y = streetaddress.substring(5);
      // d = parseInt(d)+1
      // console.log(d);
      var full = m + "/" + d + y;
      // console.log(full);
      var timestamp = Date.parse(full);
      var dateObject = new Date(timestamp);
      dateObject.setDate(dateObject.getDate() + 1);
      // console.log(dateObject);
      // const result = dateObject.toLocaleDateString("en-EN");
      // console.log(result);
      return dateObject;
    },
    convertdateb2(date) {
      if (date.getDate()) {
        return date;
      } else {
        var streetaddress = String(date);
        var d = streetaddress.substring(0, streetaddress.indexOf("/"));
        var m = streetaddress.substring(5, streetaddress.indexOf("/"));
        m = m.substring(1, 3);
        var y = streetaddress.substring(5);
        var full = m + "/" + d + y;
        // console.log(full);
        var timestamp = Date.parse(full);
        var dateObject = new Date(timestamp);
        // console.log(dateObject);
        // const result = dateObject.toLocaleDateString("en-EN");
        // console.log(result);
        return dateObject;
      }
    },
  },
  mounted() {
    
      this.getTypeMerge();
    // console.log(this.convertdate("17/12/2563"));
    // var streetaddress = "17/12/2563"
    // var d = streetaddress.substring(0, streetaddress.indexOf('/'));
    // var m = streetaddress.substring(5, streetaddress.indexOf('/'));
    // m =m.substring(1,3)
    // var y = streetaddress.substring(5);
    // // console.log(d);
    // // console.log(m);
    // // console.log(y);
    // var full = m+'/'+d+y
    // console.log(full);
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0px;
}
</style>
