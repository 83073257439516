import Vue from 'vue'
import App from './App.vue'
import LongdoMap from 'longdo-map-vue' 
import router from './router'
import VueApexCharts from 'vue-apexcharts'
import VueExcelXlsx from "vue-excel-xlsx";
import store from './store';

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

Vue.use(LongdoMap, {     
  load: {         
       apiKey: 'd5f40b42cc794284062615e558c0f75d'     
  } 
})
Vue.use(VueExcelXlsx);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
