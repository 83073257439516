<template>
  <div id="app container">
    <div v-if="show">
      <Nav />
      <div class="row">
        <div class="col-md-2" style="background-color: gray; padding-right: 0">
          <Menu />
        </div>
        <div
          class="col-md-10"
          style="padding-right: 0px !important; padding-left: 0px !important"
        >
          <router-view />
        </div>
      </div>
      <Footer />
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import Menu from "./components/Menu.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "app",
  components: {
    Nav,
    Menu,
    Footer,
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {},
  methods: {
    
  },
  mounted() {
    // console.log(this.$route.name);
    if (this.$route.params.name == "map" || this.$route.name == "login" || this.$route.name == "register") {
      this.show = false;
    }
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
</style>